import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Accordion, Container, Table, Modal, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './schedule.css';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

export default function Schedule({ api }){
    const schedule = useSelector(state => state.schedule.schedule);
    const shows = useSelector(state => state.shows.shows);
    const profile = useSelector(state => state.profile.profile);

    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE+'Schedule';
    }, []);

    let missingSlots = [];
    let overlappingSlots = [];
    let filledSchedule = {};
    const daysArray = new Array(7).fill().map((_, i) => i+1);
    const hoursArray = new Array(24).fill().map((_, i) => moment({hour: i}).format('HH:mm'));
    if(schedule !== null){
        daysArray.forEach(d => {
            missingSlots.push({day: d, hours: hoursArray});
        });
        hoursArray.forEach(hour => {
            filledSchedule[hour] = {};
            daysArray.forEach(d => {
                filledSchedule[hour][d] = null;
            });
        });
        schedule.forEach(slot => {
            const startHour = slot.TimeStart.split(':')[0];
            const endHour = slot.TimeEnd.split(':')[0];
            const sStart = moment(0).utc().hour(parseInt(startHour));
            const sEnd = moment(0).utc().hour(parseInt(endHour));

            const duration = moment(sEnd).subtract(sStart).unix()/60/60;
            for (let i = 0; i < duration; i++) {
                let tempTime = moment(slot.TimeStart, 'HH:mm').add({hour: i}).format('HH:mm');
                if(filledSchedule[tempTime][slot.Day] !== null){
                    if(overlappingSlots.filter(s => s.ScheduleID === slot.ScheduleID).length !== 0) continue;
                    overlappingSlots.push({...slot, overlapWith: filledSchedule[tempTime][slot.Day].id});
                    continue;
                }
                const missingDayIndex = missingSlots.findIndex(d => d.day === parseInt(slot.Day));
                missingSlots[missingDayIndex].hours = missingSlots[missingDayIndex].hours.filter(h => h !== tempTime);
                filledSchedule[tempTime][slot.Day] = {id: slot.ScheduleID, ShowID: slot.ShowID, duration: duration-i};
            }
        });
        var spanningDay = {1:false,2:false,3:false,4:false,5:false,6:false,7:false};

    }

    const [newTimeAway, setNewTimeAway] = useState({});

    const [timeAwayRecords, setTimeAwayRecords] = useState([]);

    // State management for the modal
    const [isTimeAwayModalOpen, setIsTimeAwayModalOpen] = useState(false);

    // Function to open the modal for time away
    const openTimeAwayModal = async () => {
        await fetchTimeAwayRecords(); // Fetch data when opening the modal
        setIsTimeAwayModalOpen(true);
    };

    // Function to close the modal
    const closeTimeAwayModal = () => {
        setIsTimeAwayModalOpen(false);
    };

    const handleTimeAwaySave = async () => {
        try {
            //console.log(newTimeAway);
            const { description, startDate, numberOfWeeks } = newTimeAway;

            if (!description || !startDate || !numberOfWeeks) {
                toast.error('Please fill in all fields.');
                return;
            }

            const startDateEpoch = Math.floor(new Date(startDate).setHours(0,0,0,0) / 1000); // Convert to epoch seconds
            
            const result = await api.createTimeAway(description, startDateEpoch, numberOfWeeks);
            if (result) {
                closeTimeAwayModal();
                setTimeAwayRecords([...timeAwayRecords, newTimeAway]);
                setNewTimeAway({ startDate: '', endDate: '', description: '' });
                toast.success('Time away record added successfully!');
            } else {
                toast.error('Failed to add time away record.');
            }
        } catch (error) {
            console.error('Error adding time away record:', error);
            toast.error('An error occurred while adding the time away record.');
        }
    };

    const handleTimeAwayDelete = async (sid) => {
        try {
            await api.deleteTimeAway(sid);
            // Refresh records after deletion
            fetchTimeAwayRecords();
            toast.success('Record deleted successfully.');
        } catch (error) {
            console.error('Error deleting time away record:', error);
            toast.error('An error occurred while deleting the time away record.');
        }
    };

    // Fetch time-away records
    const fetchTimeAwayRecords = async () => {
        try {
            const records = await api.getTimeAway();
            setTimeAwayRecords(records); // Save to state
            //console.log(records);
        } catch (error) {
            console.error('Error fetching time away records:', error);
        }
    };

    const [mondays, setMondays] = useState([]);

    // Helper function to get the upcoming Mondays
    const getMondays = () => {
        const mondaysList = [];
        const today = new Date();
        let date = new Date(today);
        // Set date to the upcoming Monday
        date.setDate(date.getDate() + (7 - date.getDay() + 1) % 7);
        
        for (let i = 0; i < 12; i++) {
            mondaysList.push(new Date(date));
            date.setDate(date.getDate() + 7); // Move to the next Monday
        }
        
        return mondaysList;
    };

    // Set Mondays list on component mount
    useEffect(() => {
        setMondays(getMondays());
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewTimeAway({ ...newTimeAway, [name]: value });
    };

    return (
        <Container className="my-4" fluid>
            <div className="schedule-header">
                <h1>Schedule</h1>
                { /* Admins only - Time away */ }
                {profile.Role == 1 &&
                    <button className='timeAwayModalButton' onClick={() => openTimeAwayModal()}><h3>Edit Time Away</h3></button>
                }
            </div>
            <hr></hr>
            {(schedule === null) &&
                <p>Retrieving the schedule &amp; shows...</p>
            }
            {schedule !== null && shows.length >= 0 &&
                <>
                    {overlappingSlots.length !== 0 &&
                        <Accordion className="mb-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="warning-alert">
                                    <h2>Warning! <p className="mb-0">The Schedule has some overlapping slots at the following times:</p></h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ol>
                                        {overlappingSlots.map((sOverlapping, sI) => {
                                            const day = moment().isoWeekday(parseInt(sOverlapping.Day)).format('dddd');
                                            const overlappingShow = shows.find(s => s.ShowID === sOverlapping.ShowID);
                                            if(!overlappingShow) return (<li key={sI}>Loading...</li>);
                                            return (
                                                <li key={sI}>
                                                    <Link to={'/shows/schedule/'+sOverlapping.ScheduleID}>{day}: {overlappingShow.Name} @ {sOverlapping.TimeStart}-{sOverlapping.TimeEnd}</Link>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }
                    {missingSlots.flatMap(ms => ms.hours).length !== 0 &&
                        <Accordion className="mb-3">
                            <Accordion.Item eventKey="0" className="danger-alert">
                                <Accordion.Header>
                                    <h2>Warning! <p className="mb-0">The schedule is missing shows from the following times:</p></h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ol>
                                        {missingSlots.map(dMissing => {
                                            const day = moment().isoWeekday(dMissing.day).format('dddd');
                                            return dMissing.hours.map((hMissing, i2) => {
                                                const hour = moment({ hour: hMissing }).format('HH:mm');
                                                return (
                                                    <li key={i2}>{day}: {hour}</li>
                                                );
                                            });
                                        })}
                                    </ol>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }
                    <Table responsive bordered className="text-center" id="scheduleTable">
                        <thead>
                            <tr>
                                <th></th>
                                {daysArray.map((d, dI) => {
                                    return (<th key={dI}>{moment().isoWeekday(d).format('dddd')}</th>);
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(filledSchedule).map(hour => {
                                const daySlots = filledSchedule[hour];
                                return (
                                    <tr key={hour}>
                                        <th>{hour}</th>
                                        {Object.keys(daySlots).map(day => {
                                            const currentShow = daySlots[day];
                                            if(spanningDay[day] && currentShow.duration === 1){
                                                spanningDay[day] = false;
                                                return null;
                                            }else if(spanningDay[day]) return null;
                                            if(currentShow !== null){
                                                if(currentShow.duration > 1){
                                                    spanningDay[day] = true;
                                                }
                                            }

                                            let overlapping = false;
                                            if(overlappingSlots.filter(s => s.overlapWith === currentShow?.id).length > 0) overlapping = true;
                                            
                                            return (
                                                <ScheduledShow
                                                    key={day}
                                                    id={currentShow?.id}
                                                    empty={currentShow === null}
                                                    showID={currentShow?.ShowID}
                                                    span={currentShow?.duration}
                                                    shows={shows}
                                                    day={day}
                                                    hour={hour}
                                                    slot={schedule.find(s => s.ScheduleID === currentShow?.id)}
                                                    overlapping={overlapping}
                                                />
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </>
            }
            <Modal show={isTimeAwayModalOpen} onHide={closeTimeAwayModal}>
                <Modal.Header closeButton>
                    <Modal.Title>View/Update Time Away</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Current Time Away:</h3>
                    {timeAwayRecords.length > 0 ? (
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {timeAwayRecords.map((record, index) => (
                                    <tr key={index}>
                                        <td>{record.Description}</td>
                                        <td>{new Date(record.TimeStart * 1000).toLocaleString()}</td>
                                        <td>{new Date(record.TimeEnd * 1000).toLocaleString()}</td>
                                        <td>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleTimeAwayDelete(record.ID)}
                                            >Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No time away records.</p>
                    )}
                    <br></br>
                    <h3>Add Time Away</h3>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={newTimeAway.description}
                                onChange={handleInputChange}
                                placeholder="Enter a description"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                as="select"
                                name="startDate"
                                value={newTimeAway.startDate}
                                onChange={handleInputChange}
                            >
                                <option value="">Select a Start Week</option>
                                {mondays.map((monday, index) => (
                                    <option key={index} value={monday.toISOString()}>
                                        {`w/c ${monday.toLocaleDateString()}`}
                                    </option>
                                ))}
                            </Form.Control>
                            
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Number of Weeks</Form.Label>
                            <Form.Control
                                as="select"
                                name="numberOfWeeks"
                                value={newTimeAway.numberOfWeeks}
                                onChange={handleInputChange}
                            >
                                <option value="">Select a number</option>
                                {[...Array(12).keys()].map((index) => (
                                    <option key={index} value={index + 1}>
                                        {index + 1} week{index > 0 ? 's' : ''}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeTimeAwayModal} className='timeAwayCancel'>Cancel</button>
                    <button onClick={handleTimeAwaySave} className='timeAwaySave'>Save</button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

function ScheduledShow({ id, empty, day, hour, slot, overlapping, shows, showID, span }){
    const navigate = useNavigate();
    if(empty) return (
        <td
            className="slot-empty"
            onClick={() => {
                navigate('/shows/schedule/'+day+'/'+hour);
            }}
        >
            <span>No Show</span>
        </td>
    );
    const show = shows.find(s => s.ShowID === showID);
    return (
        <td
            className={(overlapping && 'slot-overlap') || null}
            title={(overlapping && 'More than one show is scheduled at this hour') || show?.Name+' on '+moment().isoWeekday(parseInt(day)).format('dddd')+' @ '+hour+'-'+slot?.TimeEnd}
            onClick={() => {
                navigate('/shows/schedule/'+id);
            }}
            rowSpan={span}
            style={{verticalAlign: 'middle'}}
        >
            <span>{show?.Name}</span>
        </td>
    );
}